import React from "react";
import Accordion from "../../components/modules/Accordion";

const ApproachAccordion = () => {
  const accordionItems = [
    {
      id: "p1",
      title: "Project management",
      text: "In our studio, we use Jira to manage all our projects, giving us the ability to effectively run our sprints and track time spent on each individual ticket using Tempo.",
    },
    {
      id: "p2",
      title: "Agile Workflow",
      text: "We engage in a standard development workflow (Agile) which allows us to keep manage delivery milestones, Agile gives us the visibility to develop, test and release efficiently.",
    },
    {
      id: "p3",
      title: "Communication",
      text: "While working with us, you’ll have direct contact with the lead developer on your project, so you no longer need to jump through hoops to get clear and correct answers.",
    },
    {
      id: "p4",
      title: "Aftercare",
      text: "The development of a project is just part of the lifecycle, there is active maintenance requirements to ensure that the applications we develop stay reliable and secure.",
    },
  ];

  return <Accordion color="magenta" items={accordionItems} />;
};

export default ApproachAccordion;
