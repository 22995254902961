import React from "react";
import Layout from "../components/layout/Layout";

// Page Content
import Hero from "../components/modules/Hero";
import ApproachAccordion from "../content/ourApproach/ApproachAccordion";
import AgileWorkflow from "../content/ourApproach/AgileWorkflow";
import AgileAccordion from "../content/ourApproach/AgileAccordion";
// import OurTechnology from "../content/ourApproach/OurTechnology";
// import HardCodedNetlify from "../content/ourApproach/HardCodedNetlify";
// import Netlify from "../content/ourApproach/Netlify";
import OurTeam from "../content/OurTeam";

const ourapproach = () => {
  return (
    <Layout title="Our Approach">
      <Hero
        title="Challenging agency"
        subText="Whilst traditional agencies often supply one solution platform for all requirements and build on top of that, we make it our mission to understand your specific requirements to recommend the best technologies for you. Every one of our solutions is bespoke."
        subText2={undefined}
        color="magenta"
      />
      <ApproachAccordion />
      <AgileWorkflow />
      <AgileAccordion />
      {/* <HardCodedNetlify /> */}
      {/* <Netlify /> */}
      <OurTeam color="white" />
    </Layout>
  );
};

export default ourapproach;
