import React from "react";
import Accordion from "../../components/modules/Accordion";

const AgileAccordion = () => {
  const accordionItems = [
    {
      id: "a1",
      title: "Product Scoping",
      text: "We discover your project features and requirements and structure them appropriately into achievable milestones.",
    },
    {
      id: "a2",
      title: "Sprints",
      text: "Using our milestones, we build, test, and release your project into an evolving deployment environment, allowing you to follow along with the development process.",
    },
    { 
      id: "a3", 
      title: "Product Release", 
      text: "After the project has been signed off, we can get to work handing over your project to your team for production deployment or host it in a tailor-made environment to meet your business requirements." 
    },
    { id: "a4", title: "Product Increment", text: "Now you've got a great product, we're here to help you improve and maintain it using the same workflow as the original build." },
  ];

  return <Accordion color="black" items={accordionItems} />;
};

export default AgileAccordion;
