import React from "react";
import AccordionItem from "../ui/AccordionItem";
import moduleColors from "./modules-colors";
import Button from "../ui/Button";

const Accordion = ({ title, color, items, btnText, btnTo }) => {
  const { bgColor, btnColor } = moduleColors(color);

  return (
    <section className={bgColor}>
      <div className="px-12 py-16 max-width">
        {title && <h2 className="text-3xl md:text-6xl mb-16">{title}</h2>}
        <div className="flex flex-col">
          {items.map((item, index) => {
            return (
              <div key={item.id}>
                <AccordionItem
                  id={item.id}
                  title={item.title}
                  text={item.text}
                  buttonText={item.buttonText}
                  buttonLink={item.buttonLink}
                  color={color}
                  index={index}
                />
              </div>
            );
          })}
        </div>
        {btnText && (
          <div className="mt-15 w-max">
            <Button color={btnColor} size="small" to={btnTo}>
              {btnText}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Accordion;
