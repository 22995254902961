import React from "react";
import Button from "../ui/Button";
import moduleColors from "./modules-colors";

const TextImage = (children) => {
  // Class width and layout
  const sectionClass =
    "max-width py-16 px-12 grid grid-cols-1 gap-3 items-center " +
    (children.fullWidth || !children.image ? " " : "md:grid-cols-2 ");

  const { bgColor, btnColor } = moduleColors(children.color);

  return (
    <section className={bgColor}>
      <div className={sectionClass}>
        <article>
          {children.title && (
            <h2 className="text-3xl md:text-6xl mb-8">{children.title}</h2>
          )}
          {children.Title && children.Title}
          <p
            className={
              "text-xl md:text-2xl md:mb-8 " +
              (children.fullWidth ? "md:w-3/5" : "w-full")
            }
          >
            {children.text}
          </p>
          {children.btnText && (
            <Button color={btnColor} to={children.to}>
              {children.btnText}
            </Button>
          )}
        </article>
        {!!children.image && (
          <div className="md:p-1 justify-center items-center hidden md:flex">
            <div className="hidden md:flex md:w-10/12">
              {
                children.image
              }
            </div>
            {/* <GatsbyImage image={children.image} alt="Test" /> */}
          </div>
        )}
      </div>
    </section>
  );
};

export default TextImage;
