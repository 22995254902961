import React, { useState } from "react";
import Button from "./Button";
import moduleColors from "../modules/modules-colors";
import { HiChevronDown } from "react-icons/hi";

const AccordionItem = ({
  id,
  title,
  text,
  buttonText,
  color,
  buttonLink = "/contact",
  index,
}) => {
  const [show, setShow] = useState(false);
  const showHandler = () => {
    setShow(!show);
  };

  const { btnColor, iterateClass } = moduleColors(color);

  return (
    <article className="py-8 w-full border-top">
      <div
        className="w-full flex justify-between items-center cursor-pointer"
        onClick={showHandler}
        role="row"
        tabIndex={0 - index}
        onKeyPress={showHandler}
      >
        <h3
          className={
            "text-xl md:text-4xl hover:opacity-75 cool-sans " + iterateClass(index)
          }
        >
          {title}
        </h3>
        <span>
          <HiChevronDown
            className={
              "text-4xl transition-all ease-in-out " +
              (show && "transform rotate-180")
            }
          />
        </span>
      </div>
      {show && (
        <div className="flex flex-col items-start transition-all duration-500 delay-300 ease">
          <p className="text-lg md:text-xl mb-3 w-full md:w-3/5 pt-5 font-light">
            {text}
          </p>
          {buttonText && (
            <div className="mt-5">
              <Button size="small" to={buttonLink} color={btnColor}>
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      )}
    </article>
  );
};

export default AccordionItem;
