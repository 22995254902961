import React from "react";
import TextImage from "../../components/modules/TextImage";

import AgileImage from '../../images/CS Process.svg'

const AgileWorkflow = () => {
  return (
    <div>
      <TextImage
        title="Our Agile Workflow"
        text="Agile is a concept, and we’ve embraced a standard approach to the workflow, which allows us to work and deliver milestones effectively."
        color="black"
        image={<img src={AgileImage} alt="" />}
        fullWidth={true}
      />
    </div>
  );
};

export default AgileWorkflow;
